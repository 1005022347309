@use '@on3/styles/variables' as var;

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0 0 0 / 65%);
  z-index: 10002;
  overflow-y: auto;
  display: grid;
  place-items: center;
  padding: 1rem;

  @media screen and (min-width: var.$tablet-width) {
    padding: 2rem;
  }
}

.inner {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}

.modal {
  position: relative;
  max-width: var.$tablet-width;
  width: 100%;
  max-height: 100%;
  border-radius: var.$radius-sm;
  margin: 0 auto;

  .sm {
    max-width: var.$small-width;
  }

  .md {
    max-width: var.$mid-width;
  }

  .lg {
    max-width: var.$desktop-width;
  }

  .xl {
    max-width: var.$full-width;
  }
}

.close {
  appearance: none;
  border: none;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  cursor: pointer;

  svg {
    width: var.$icon-small;
    height: var.$icon-small;
    fill: var.$gray;
  }
}
