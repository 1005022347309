@use '@on3/styles/elite/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

:export {
  primaryColor: var.$elitePrimary;
}

.modal {
  background: var.$white;
  padding: var.$spacing-regular;

  @media screen and (min-width: var.$mid-width) {
    padding: var.$spacing-lg;
  }

  h2 {
    @extend %h1;

    text-align: center;
    margin-bottom: var.$spacing-sm;
    font-size: clamp(1.25rem, 2.5vw, 1.5rem);
  }

  nav {
    @include mix.flex($align: stretch, $gap: var.$spacing-xs);

    margin-bottom: var.$spacing-lg;

    .navItem {
      @include mix.flex($justify: center, $gap: var.$spacing-sm);

      flex: 1;
      border-bottom: 6px solid var.$lightNeutralGray;
      padding: var.$spacing-sm;

      svg {
        width: auto;
      }

      &.active {
        border-color: var.$on3primary;
        font-weight: 700;
      }
    }
  }
}
