@use '@on3/styles/elite/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  display: grid;
  gap: var.$spacing-lg;

  .group {
    @include mix.spaceY(var.$spacing-sm);
  }

  input {
    @extend %input;
  }

  [data-ui='checkbox'] {
    @extend %checkbox;
  }

  .submit {
    @include mix.flex($justify: flex-end);

    padding-top: var.$spacing-sm;
    border-top: 1px solid var.$lightNeutral;

    button[type='submit'] {
      @extend %btn-primary;
    }
  }
}
